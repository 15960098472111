import React, { useState, useEffect, useRef } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../Pages/home.module.css';
import lady1 from '../assets/lady1.png';
import lady2 from '../assets/lady2.png';
import man1 from '../assets/man1.png';
import julia from '../assets/ julia.jpeg';

const AutoScrollCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const carouselRef = useRef(null);

  const handleSlide = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (carouselRef.current) {
      observer.observe(carouselRef.current);
    }

    return () => {
      if (carouselRef.current) {
        observer.unobserve(carouselRef.current);
      }
    };
  }, []);

  const renderCarouselItem = (imgSrc, name, description, index) => (
    <Carousel.Item key={name} className={styles.carouselItem} data-index={index}>
      <div
        className={`d-flex align-items-center justify-content-around h-100 ${isVisible ? styles.fadein : '' // Apply fadeIn class only when isVisible is true
          }`}
      >
        <div className="circle-image" id={styles.carouselimage}>
          <img className="d-block rounded-circle" src={imgSrc} alt={`${name} avatar`} />
        </div>
        <div className={`carousel-text ${styles.descriptionContainer}`}>
          <h3 id={styles.carouseldesc}>{name}</h3>
          <p id={styles.carouseldesc}>{description}</p>
        </div>
      </div>
    </Carousel.Item>
  );

  return (
    <div className={styles.carouselContainer} ref={carouselRef}>
      <Carousel
        id={styles.care}
        interval={4000}
        pause="hover"
        indicators
        controls
        activeIndex={activeIndex}
        onSelect={handleSlide}
      >
        {renderCarouselItem(
          'https://www-s3-live.kent.edu/s3fs-root/s3fs-public/styles/profile_photo_internal/public/Linda%20edit.jpg?VersionId=5fD3G8bSVh0ndRCzDsk1pvPQT4nxYrxG&h=43f991c4&itok=YG3qZmmX',
          'LINDA OHRN-MCDANIEL',
          "Linda Ohrn-McDaniel is a Professor at Kent State University's School of Fashion, specializing in knitted textiles. She leads the KnitLAB and focuses on interdisciplinary research and industry collaborations. Linda developed the knitting specialization at Kent State and teaches courses in Fashion Design and Patternmaking. Her work explores the interaction of shape and surface in design. She holds an MFA from the University of North Texas and a Bachelor's degree from Uppsala University, Sweden.",
          0
        )}
        {renderCarouselItem(
          'https://vbu-berater.de/images/comprofiler/537_5c2b498d8acbc.jpg',
          'Thomas Mutschler',
          'Technological Advancements in Knitwear at the University of Applied Sciences Niederrhein, led by the accomplished Thomas Mustchler. As the driving force in technology development for knitwear, he combines expertise and innovation. Join the journey guided by his leadership, shaping the future of knitwear technology at IFKT Conference 2024.',
          1
        )}
        {renderCarouselItem(
          'https://www.hs-niederrhein.com/fileadmin/pool-ordner/bilder/Portraitfotos/FB07/Weber.jpg',
          'Marcus O. Weber',
          'Textile Technology, with a special emphasis on spinning technology, as well as expertise in management science and pedagogy, defines the focus of TUB/Textile Management under the capable leadership of Marcus O. Weber. Holding a pivotal role in steering the program, Marcus O. Weber brings a wealth of knowledge and a commitment to excellence.',
          2
        )}
        {renderCarouselItem(
          'https://www.autex.org/sites/default/files/team/Image_of_Mirela_Blaga_10b_237_284.jpg',
          'Mirela Blaga',
          'Professor Mirela Blaga, Director at Gheorghe Asachi Technical University, is a prominent leader in textile research, serving as an expert evaluator for EU programs and holding key roles in AUTEX and IFKT. Her impactful contributions extend to project management, editorial roles, and international engagements, solidifying her influential presence.',
          3
        )}
        {renderCarouselItem(
          julia,
          'Julia Klausmann',
          'Julia Klausmann, a Textile and Design Engineer in R&D at Hochschule Niederrhein, excels in sustainable development of technical textiles. Awarded the Promos Scholarship, her impactful contributions span eLearning administration and an Adidas internship.',
          4
        )}
        {renderCarouselItem(
          'https://www.ulster.ac.uk/staff-images/images/gault-1.jpg',
          'Alison Gault',
          'Explore the Frontier of Textile Design and Research at Ulster University, guided by the experienced Alison Gault. With a rich background in fashion and textiles, Alison spearheads innovative projects in knitwear technology and design, contributing to advancements in materials and craftsmanship. Join her pioneering initiatives and shape the future of textile innovation at upcoming conferences and research endeavors.',
          5
        )}
        {renderCarouselItem(
          'https://www.aust.edu/storage/faculty_photo/061008.jpeg',
          'Dr. A. K. M. Mobarok Hossain',
          "Dr. A. K. M. Mobarok Hossain is an Associate Professor in the Department of Textile Engineering at Ahsanullah University of Science and Technology. He holds a Ph.D. in Knitting Mechanics and has research interests in knitting technology. His work includes numerous publications on circular weft knitting machines and fabric performance. Dr. Hossain's educational background includes degrees from BUET and BUTEX.",
          6
        )}
        {renderCarouselItem(
          'https://i1.rgstatic.net/ii/profile.image/11431281224918279-1708504415204_Q512/Prisca-Holderied.jpg',
          'Prisca Holderied',
          "Prisca Holderied is a Master of Science student at Hochschule Niederrhein (HSNR) in the Textile and Clothing Technology program. She is focused on advancing her knowledge and skills in textile engineering. Her studies are aimed at integrating innovative solutions in the textile industry.",
          7
        )}
        {renderCarouselItem(
          'https://asnicolas.weebly.com/uploads/6/4/2/1/64212389/published/new-photo-01.jpg?1712337404',
          'Audrey Nicolas',
          "Audrey Nicolas is a seasoned fashion designer with over 18 years of international experience, specializing in knitwear. Her designs have been showcased on notable figures, including Michelle Obama. Audrey's diverse portfolio includes work with Abercrombie & Fitch and Mango, and she is actively involved in education and community engagement. She holds a degree from Atelier Chardon-Savard in Paris and leads workshops while serving as a board member of Passport2Fashion and a faculty member at CCAD.",
          8
        )}
      </Carousel>
    </div>
  );
};

export default AutoScrollCarousel;
